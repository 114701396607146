.overlay-box {
    position: relative;
    border: 5px black solid;
}

.overlay-box:hover .description-box {
    animation-name: fade-in;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    color: black;
    font-weight: 500;
}

@keyframes fade-in {
    from {
        opacity: .1
    }
    to {
        opacity: 1
    }
}

@keyframes fade-out {
    from {
        opacity: 1
    }
    to {
        opacity: .05
    }
}

@keyframes play-gif {
    from {
        opacity: 0
    }
    to {
        opacity: .4
    }
}

.overlay-box:hover .description-img {
    animation-name: fade-out;
    animation-duration: .5s;
    animation-timing-function: linear;
    opacity: .05;
}

.overlay-box:hover .description-gif {
    animation-name: play-gif;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.description-img {
    position: relative;
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.description-gif {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
}

.description-box {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    position: absolute;
    font-size: 2rem;
    text-align: center;
    color: black;
    font-size: 2rem;
    top: 0;
    opacity: 0;
}

.description-text {
    height: 100%;
    width: 100%;
    position: relative;
    margin: 2.5vw;
    font-size: 1.2rem;
    font-family: Jost, Helvetica, Arial, sans-serif;
}

.description-title {
    position: absolute;
    color: black;
    font-size: 1.5rem;
    font-weight: 300;
}